.followers-card {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

.followers-card .followers-card-image {
  max-width: 80px;
  width: 100%;
  border-radius: 100%;
  height: 80px;
  width: 80px;
}

.followers-card .followers-card-name {
  font-family: LatoBold;
  font-size: 16px;
  color: #555555;
  margin: 0;
}
