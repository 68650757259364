.opinion-container .title {
  font-family: LatoBold;
  font-size: 22px;
}

.opinion-container .description {
  font-family: Lato;
  font-size: 18px;
  color: #3b3b3b;
}
.opinion-container .opinion-container-box {
  border-radius: 10px;
  border: 1px solid #f3f3f3;
  background-color: #fff;
  padding: 20px;
}
