.cardBox {
  background-color: white;
  box-shadow: 0 0 12px -10px #000;
  max-width: 400px;
  width: 100%;
  margin: 5px auto;
  padding: 10px;
  position: relative;
}
.cardBox .heart-icon {
  position: absolute;
  right: 5px;
  top: 20px;
  cursor: pointer;
  font-size: 20px;
}
.cardBox .heart {
  color: #d7d7d7;
}
.cardBox .heart-liked {
  color: #6b0019;
}
.cardBox img {
  width: 100%;
  height:300px;
  object-fit: cover;
  /*padding: 0 10px;*/
}
.cardBox h5 {
  padding: 0;
  font-size: 14px;
  font-weight: bolder;
  margin-top:10px;
  background-color: #fff;
}
.cardBox p {
  font-size: 0.8rem;
  padding: 0px;
  color: gray;
  background-color: #fff;
  margin: 0;
}
.cardBox h3 {
  font-style: italic;
  background-color: #fff;
  font-family: LatoBoldItalic;
  padding: 5px 0;
  margin: 0;
}
.cardBox p i {
  color: #ff4500;
  background-color: #fff;
}
.cardContent {
  /* border: 1px solid ; */
  background-color: #fff;
}
.cardImg {
  position: relative;
}
.cardImg i {
  /* border: 1px solid; */
  font-size: 1.5rem;
  position: absolute;
  top: 5%;
  right: 5%;
}

@media only screen and (max-width: 1190px) {
  .cardContent h5 {
    font-size: 0.9rem;
  }
}
@media only screen and (max-width: 1085px) {
  .cardContent h5 {
    font-size: 0.9rem;
  }
}
