.footerBox {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  background: white;
}
.footerText {
  margin: 0 50px;
}
.footerText a {
  color: gray;
}
.footerText a {
  margin: 0 10px;
  font-family: LatoBold;
}
.cgv-border {
  padding: 0 10px;
  /*border-left: 1px solid gray;*/
  border-right: 1px solid gray;
}
