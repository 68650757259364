.bottleContentParent {
  padding: 18px;
}
.bottleContent {
  background-color: white;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  background-color: white;
}
.bottleContent .bottle-content-box {
  border-bottom: 1px solid #f8f8f8;
  padding: 15px;
}
.bottleContent .bottle-content-box .bottle-content-detail {
  display: flex;
  justify-content: space-between;
}
.bottleContent .bottle-content-box .label-light {
  font-family: Lato;
  color: gray;
  font-size: 16px;
  font-style: italic;
}

.bottleContent .bottle-content-box .bottle-content-input {
  border: 0;
  outline: 0;
  width: 100%;
  font-family: LatoBold;
  font-size: 16px;
  color: #3B3B3B;
}

.bottleContent .bottle-content-box .bottle-content-input::placeholder {
  color: #a9a9b0;
  opacity: 1; /* Firefox */
}

.bottleContent .bottle-content-box .bottle-content-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a9a9b0;
}

.bottleContent
  .bottle-content-box
  .bottle-content-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a9a9b0;
}

.bottle-content-description {
  border: 0;
  outline: 0;
  width: 100%;
  font-family: LatoBold;
  font-size: 16px;
}

.bottle-content-description::placeholder {
  color: #a9a9b0;
  opacity: 1; /* Firefox */
}
.bottle-content-description:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a9a9b0;
}

.bottle-content-description::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a9a9b0;
}
.bottleContent .edit-bottle-content {
  padding: 15px;
}

.bottleContent h5 {
  font-size: 1.2rem;
}
.removeBorder {
  padding: 20px 0;
  border: none !important;
}
.currency-wrap{
  position:relative;
}
.currency-code{
  position:absolute;
  left:8px;
  top:10px;
}
.circleChecked{
  filter: invert(5%) sepia(71%) saturate(6017%) hue-rotate(336deg) brightness(95%) contrast(103%);
}
.circleUncheck{
  filter: invert(50%) sepia(5%) saturate(5%) hue-rotate(65deg) brightness(94%) contrast(86%);
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  opacity: 0.8;
  background-color: #000;
}