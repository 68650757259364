.profile-card {
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  background-color: white;
  margin: 0;
  margin-top: 60px;
  padding: 20px;
}

.profile-card .user-profile {
  max-width: 150px;
  max-height: 150px;
  object-fit: cover;
  border-radius: 100px;
  width: 140px;
  height: 140px;
}

.profile-card .name {
  font-size: 22px;
  color: #555555;
  font-family: LatoBold;
  margin: 0;
  padding: 0;
}

.profile-card .description {
  font-size: 15px;
  color: #555555;
  font-family: Lato;
  margin: 0;
  padding: 0;
}
.profile-card .location {
  font-size: 15px;
  color: #555555;
  font-family: Lato;
  margin: 0;
  padding: 0;
}

.profile-card .info {
  font-size: 18px;
  color: #555555;
  font-family: LatoBold;
  margin: 0;
  padding: 0;
}

.profile-card .info-value {
  font-size: 18px;
  color: #555555;
  font-family: Lato;
}

.profile-card .profile-btn-box {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.profile-card .profile-btn-box .purple-btn {
  color: #6b0019;
  min-width: 150px;
  border-radius: 10px;
  border: 2px solid #6b0019;
  font-family: LatoBold;
  padding: 10px !important;
  margin: 5px;
}
.profile-card .profile-btn-box .purple-btn-full {
  color: #fff;
  background-color: #6b0019 !important;
  min-width: 150px;
  border-radius: 10px;
  border: 2px solid #6b0019;
  font-family: LatoBold;
  padding: 10px !important;
  margin: 5px;
}
.profile-card .profile-btn-box .purple-btn:hover {
  background-color: #6b0019 !important;
  color: white !important;
}
.profile-card .profile-btn-box .outline-purple-btn {
  background-color: #6b0019 !important;
  min-width: 150px;
  border-radius: 10px;
  color: white !important;
  border: 2px solid transparent;
  padding: 10px !important;
  margin: 5px;
}
.profile-card .profile-btn-box .outline-purple-btn:hover {
  background-color: rgb(58, 4, 4) !important;
  color: white !important;
}
i.fa-star{
  color: #ff4500;
  background-color: #fff;
}
