.forMembersSection {
}


.sectionTitle {
  padding: 20px 0;
  /* border: 1px saddlebrown solid; */
  font-family: "Lato", sans-serif;
  /* margin-top: 140px; */
  /* text-align: center; */
}
