.modifySectionParent {
  padding: 30px;
}
.modifySection {
  margin-top: 30px;
  padding: 20px;
  background-color: white;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
}
.modifyTitle {
  font-size: 32px;
  color: gray;
  font-family: Lato;
}
.modifySection .modifyDescrition {
  color: #1B1F2A;
  font-family: Lato;
}
.dragParent {
  width: 100%;
  padding: 30px;
  /* display: flex; */
}

.dropZone {
  /* border: 2px  dashed gray; */
  background-color: white;
  /* min-height: 10vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* height: 100px; */
  /* text-align: center; */
  /* margin: 16px 32px; */
}
.dropZone img {
  width: 200px;
}

.selectedImg {
  height: 200px;
  width: 200px;
  object-fit: cover;
  margin: 16px;
  /* border: 1px solid; */
  /* background: grey; */
}
.App {
  /* position: relative; */
  /* border: 1px dashed red; */
  border: 2px dashed gray;
  padding: 10px;
  /* height: 300px; */
  /* flex: 1; */
}

.imgCont {
  position: 0;
  border: 1px solid;
}

@media only screen and (max-width: 680px) {
  .dragBoxParent {
    justify-content: center;
  }
  .dragText {
    display: none;
  }
}
/* drop.css.*/
.App {
  font-family: sans-serif;
  text-align: center;
}
