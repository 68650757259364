
/* Images Component CSS */

.grid-container-fb {
    text-align: center;
    margin: auto;
    width: 100%;
    padding: 10px;
}

.container-fb {
    padding-left: 0;
    padding-right: 0;
}
.container-fb-1 {
    width:75%
}
.container-fb-2 {
    width:24.6%
}

.container-fb *{
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.col-md-4-fb {
    width: 33.33333333%;
}

.border-fb {
    border: 2px solid white;
    border-radius: 6px;
}

.background-fb {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}

.height-one {
    width: 100%;
    padding-top: 100%;
}

.height-two {
    width: 100%;
    padding-top: 100%;
    max-width:100%

}

.height-three {
    width: 100%;
    padding-top: 100%;
    max-width:100%
}

.cover-fb {
    background-color: #222;
    opacity: 0.8;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 6px;
}

.cover-text {
    right: 0;
    left: 0;
    bottom: 0;
    color: white;
    font-size: 7%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    text-align: center;
}

.cover-text > p {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.slide-fb {
    height: 0;
    bottom: 100%;
    transition: .5s ease;
    overflow: hidden;
    font-size: 3%;
}

.border-fb:hover .slide-fb  {
    bottom: 0;
    height: auto;
}

.border-fb:hover .animate-text  {
    top: 62%
}