.edit-profile-card {
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  background-color: white;
  margin: 0;
  margin-top: 60px;
  padding: 20px;
}

.edit-profile-card .user-edit-profile {
  border-radius: 100px;
  object-fit: contain;
  width: 200px;
  height: 200px;
}
.edit-profile-card .edit-profile {
  font-family: Lato;
  text-align: center;
  cursor: pointer;
}
.edit-profile-card .section-title {
  font-family: LatoBold;
  margin: 0;
  padding: 10px;
  font-size: 22px;
}
.edit-profile-card .text-field-box {
  margin: 20px 0;
}
.edit-profile-card .text-field-box .input-label {
  font-family: LatoLightItalic;
  display: block;
  margin: 0;
}

.edit-profile-card .text-field-box .input {
  font-family: LatoBold;
  border: 0;
  border-bottom: 1px solid #f8f8f8;
  outline: none;
  padding: 10px 0;
  width: 100%;
}

.edit-profile-card button {
  color: #6b0019;
  min-width: 200px;
  border-radius: 10px;
  border: 2px solid #6b0019;
  font-family: LatoBold;
  padding: 10px;
  background-color: transparent;
}
.edit-profile-card button:hover {
  background-color: #6b0019;
  color: white;
}
