.section-title {
    font-family: LatoBold;
    margin: 0;
    padding: 10px;
    font-size: 22px;
}
.customBtn {
    color: #6b0019;
    min-width: 300px;
    border-radius: 10px;
    border: 2px solid #6b0019;
    font-family: LatoBold;
    padding: 10px;
    background-color: transparent;
    cursor:pointer;
    width:300px;
}
.customBtn:hover {
    background-color: #6b0019;
    color: white;
    cursor:pointer;
}