.forMembersSection {
}

.profile-prducts .outline-purple-btn {
  background-color: #6b0019 !important;
  min-width: 150px;
  border-radius: 10px;
  color: white !important;
  border: 2px solid transparent;
  padding: 10px !important;
  margin: 5px;
}

.sectionTitle {
  /* border: 1px saddlebrown solid; */
  font-family: "Lato", sans-serif;
  /* margin-top: 140px; */
  /* text-align: center; */
}
