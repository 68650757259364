.horizontal-card {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

.horizontal-card .horizontal-card-image {
  max-width: 150px;
  width: 100%;
  border-radius: 100px;
  max-height: 150px;
  object-fit: cover;
}

.horizontal-card .horizontal-card-name {
  font-family: LatoBold;
  font-size: 16px;
  color: #555555;
  margin: 0;
}
.horizontal-card .horizontal-card-time {
  font-family: LatoBold;
  color: #c3c3c3;
  margin: 0;
  font-size: 16px;
  margin-left: 10px;
}
.horizontal-card .horizontal-card-prize {
  font-family: LatoBold;
  font-size: 22px;
}
.horizontal-card .type-success {
  color: rgb(143, 206, 119);
}
.horizontal-card .type-danger {
  color: #eb7577;
}

.horizontal-card .type-warning {
  color: #f5e893;
}
.horizontal-card .horizontal-card-description {
  margin: 0;
  padding: 0;
  font-family: Lato;
  font-size: 16px;
  font-weight: 800;
}
.horizontal-card.cursor-pointer{
  cursor:pointer;
}
