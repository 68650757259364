.navParent {
  width: 100%;
  background-color: #fff;
}
.navBar {
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
}
.navBar ul {
  display: flex;
  list-style: none;
  margin: 5px 15px;
}
.navBar ul li a {
  text-decoration: none;
  color:#000;
  margin: 0 20px;
  font-size: 1rem;
  font-family: LatoBold;
}
.navBar ul li{
  margin: 0 20px;
  font-size: 1rem;
  font-family: LatoBold;

}
.navBar ul li i {
  margin: 0 7px;
}
.bottomNav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.logoBox {
  /* border: 1px solid; */
  width: 30%;
  padding-left: 20px;
}
.logoBox img {
  margin-left: 10px;
}
.inputBox {
  width: 60%;
  margin-top: 5px;
  /* border: 1px solid red; */
}
.inputBox input {
  outline: none;
  border: 1px solid lightgray;
}
.inputBox input:focus {
  outline: none;
  box-shadow: 0 0 0;
  border-color: #a52a2a;
}
.inputBox button {
  background-color: #a52a2a !important;
  color: white;
  width: 50px;
  font-size: 20px;
}
.inputBox button i {
  background-color: #a52a2a !important;
}
.inputBox button:hover {
  background-color: #8b0000;
}
@media only screen and (max-width: 940px) {
  .navBar {
    justify-content: center;
  }
}

@media only screen and (max-width: 570px) {
  .navBar ul li {
    font-size: 0.8rem;
    margin: 0 2px;
  }
  .logoBox {
    width: 100%;
    text-align: center;
    /* border: 1px solid red; */
  }
  .inputBox {
    margin: 0 auto;
    width: 97%;
  }
}
@media only screen and (max-width: 500px) {
  .navBar ul li {
    font-size: 0.5rem;
    margin: 0 5px;
  }
}
