.FeedBackBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.messageParent {
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
 /* min-height: 80vh;*/
  background: white;
  flex-direction: column-reverse;

  display: flex;
 /* flex-direction: column;*/
  flex-wrap:nowrap;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #f3f3f3;
  background-color: #fff;
}
.mainBoxMessage {
  padding: 5px;
  display: flex;
  flex-direction: column-reverse;
}
.messageBox {
  width: 100%;
  display: flex;
  min-height: 100px;
  justify-content: center;
  flex-wrap: wrap;
}
.messageBox .message {
  padding: 20px;
  width: 80%;
  border-radius: 10px;
  background-color: #6b0012;
  color: white;
  min-height: 100px;
}
.messageBox .messageImg {
  max-width: 80px;
  margin-left: 5px;
}
.messageBox .messageImg img {
  width: 37px;
  height: 37px;
  border-radius: 360%;
  margin-left: 10px;
}
.messageBox2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100px;
}
.message2 {
  border: 1px solid lightgray;
  width: 80%;
  border-radius: 8px;
  padding: 20px;
  min-height: 100px;
}
.messageImg2 {
  max-width: 80px;
  margin-right: 5px;
}
.messageImg2 img {
  width: 37px;
  height: 37px;
  border-radius: 360%;
}

#inputBox {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
  align-items: center;
}
#setWidth {
  width: 100%;
  background-color: #f4f4f4;
  border-radius: 2px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}
#msgInput {
  width: 100%;
  border: none;
  background-color: #f4f4f4;
  padding: 8px;
  outline: none;
  border-radius: 2px;
}
/*
#msgInput {
  border: none;
  background-color: #f4f4f4;
  padding: 10px;
  outline: none;
  width: 100%;
  border-radius: 10px;
  flex: 1
} */
.fa-arrow-right {
  position: absolute;
  top: 25%;
  right: 15%;
  color: gray;
  font-size: 1.5rem;
  cursor: pointer;
}
.fa-camera {
  font-size: 2rem;
  display: inline-block;

  margin: 8px 50px 0 10px;
}
.sideParent {
  flex: 1;
}
.sideBox {
  border-radius: 10px;
  border: 1px solid #f3f3f3;
  background-color: #fff;
  width: 100%;
  padding: 20px;
}
.sideBox h5 {
  font-weight: bolder;
  font-style: italic;
}
.sideBox p {
  font-size: 2.5rem;
  color: #1b1f2a;
}
.sideBox .red-btn {
  background-color: #6b0012;
  width: 100%;
  color: white;
  border: none;
  border-radius: 10px;
  transition: 0.5s;
  font-family: LatoBold;
  padding: 10px;
  margin: 2px;
}
.sideBox .red-btn:hover {
  background-color: #710119;
}
.sideBox .red-btn-outline {
  color: #6b0019;
  width: 100%;
  border-radius: 10px;
  border: 2px solid #6b0019;
  font-family: LatoBold;
  padding: 10px;
  margin: 2px;
  background: transparent;
}
.sideBox .red-btn-outline:hover {
  background-color: rgb(58, 4, 4);
  color: white;
}
.sidebox2 {
  padding: 20px;
}
.sideCont {
  display: flex;
  align-items: baseline;
  color: #a52a2a;
}
.sideCont p {
  margin: 0 15px;
  background-color: transparent;
}
.sideCont2 {
  display: flex;
  margin: 10px 0;
  align-items: baseline;
  color: gray;
}
.sideCont2 p {
  margin: 0 15px;
  background-color: transparent;
}
.options p {
  font-size: 15px;
  margin: 0;
  font-family: sans-serif;
  font-weight: 300;
}
.options b {
  font-size: 15px;
  margin: 0;
  font-family: sans-serif;
  color: #1b1f2a;
}

.offer-modal-header {
  text-align: center;
  position: relative;
  padding: 5%;
  border-bottom: 1px solid #dfdfdf;
}
.offer-modal-header .offer-modal-title {
  font-size: 24px;
  font-family: LatoBold;
  color: #555555;
}
.offer-modal-header .offer-modal-close-icon {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 22px;
  font-family: LatoBold;
}

.time-box {
  text-align: center;
}

.time-box .time {
  font-family: Lato;
  color: #555555;
  font-size: 22px;
  padding: 5%;
  border-bottom: 1px solid #dfdfdf;
}

.offer-modal-description {
  color: #7B7B7B;
  font-size: 15px;
  padding: 0 2% ;
  font-family: LatoBold;
}
@media only screen and (max-width: 850px) {
  .messageBox {
    flex-direction: column-reverse;
    align-items: center;
  }
  .messageBox .messageImg {
    margin: 5px;
  }
}
@media only screen and (max-width: 615px) {
  .FeedBackBox {
    flex-direction: column-reverse;
  }
  .messageBox {
    padding: 0;
  }
  .messageParent {
    width: 100%;
  }
  .messageImg2 {
    margin-bottom: 5px;
  }
  .sideBox {
    width: 100%;
    margin: 0 !important;
  }
}
