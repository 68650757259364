.card_modal {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f9;
  padding: 10px;
  width: 100%;
  margin-bottom: 30px;
  transition: 0.3s;
}

.btn-modal {
  width: 30vmin;
  max-width: 100%;
  border-radius: 26px;
  border-color: #6a0013;
  color: #6a0013;
}

.search {
  position: relative;
}
.search textarea {
  text-indent: 8px;
  max-width: 100%;
  width: 275px;
  height: 87px;
  border-radius: 2px;
  margin-top: 10px;
  border-color: #e5e5e5;
  padding: 5px;
}
.search .fa-face-grin {
  position: absolute;
  top: 25px;
  right: 10px;
  font-size: 15px;
  font-weight: lighter;
  color: #e5e5e5;
}

.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(33% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.submit-card p {
  font-size: 15px;
}
.modal-body button {
  color: #6b0019;
  min-width: 200px;
  border-radius: 10px;
  border: 2px solid #6b0019;
  font-family: LatoBold;
  padding: 10px;
  background-color: transparent;
}
.modal-footer button {
  color: #6b0019;
  min-width: 200px;
  border-radius: 10px;
  border: 2px solid #6b0019;
  font-family: LatoBold;
  padding: 10px;
  background-color: transparent;
}
.modal-body button:hover {
  background-color: #6b0019;
  color: white;
}

@media only screen and (max-width: 678px) {
  .submit-card{
    width: 70vw;
    display: none;
  }
}


.modalBoost .text-field-box {
  margin: 20px 0;
}
.modalBoost .text-field-box .input-label {
  font-family: LatoLightItalic;
  display: block;
  margin: 0;
}

.modalBoost .text-field-box .input {
  font-family: LatoBold;
  border: 0;
  border-bottom: 1px solid #f8f8f8;
  outline: none;
  padding: 10px 0;
  width: 100%;
}

.modalBoost button {
  color: #6b0019;
  min-width: 200px;
  border-radius: 10px;
  border: 2px solid #6b0019;
  font-family: LatoBold;
  padding: 10px;
  background-color: transparent;
}
.modalBoost button:hover {
  background-color: #6b0019;
  color: white;
}

.lotModal .purple-btn {
  color: #6b0019;
  min-width: 150px;
  border-radius: 10px;
  border: 2px solid #6b0019;
  font-family: LatoBold;
  padding: 10px !important;
  margin: 5px;
}
.lotModal .purple-btn:hover {
  background-color: #6b0019 !important;
  color: white !important;
}