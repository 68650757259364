.reviewParent {
  width: 100%;
  flex-wrap: wrap;
}
.reviewBox {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  box-shadow: 0 0 15px -12px #000;
  background-color: white;
  align-items: center;
}

.reviewImg {
  width: 80px;
  margin-right: 10px;
  background-color: transparent;
}
.reviewImg img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background-color: transparent;
}
.reviewStar {
  display: flex;
  justify-content: space-between;
  background-color: transparent !important;
}
.reviewStar i {
  color: #ff4500;
  background-color: transparent !important;
}
.reviewBox h5 {
  margin: 0;
  margin-top: 5px;
  font-size: 1.1rem;
  font-family: LatoBold;
}
.reviewBox p {
  padding-top: 5px;
  color: gray;
  margin: 0;
  width: 290px;
  font-family: Lato;
}

.starCont {
  color: gray;
  font-family: LatoBold;
}

.reviewTittle {
  text-align: center;
  font-family: Lato;
}

.next {
  color: gray !important;
}

@media only screen and (max-width: 770px) {
  .reviewBox {
    width: 95%;
    margin: 10px 5px !important;
  }
}

@media only screen and (max-width: 670px) {
  .reviewBox {
    width: 50%;
    margin: 10px 5px !important;
  }
  .reviewBox img {
    margin: 5px;
  }
  .reviewBox p {
    width: 70%;
  }
  .reviewBox {
    margin: 5px;
    /* display: inline-block; */
    font-size: 0.6rem;
  }
}
