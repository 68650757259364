.filter-dropdown {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  font-family: Lato;
  border: 1px solid #e4e4e4;
}

.product-list .section-title {
    font-family: Lato;
    font-size: 22px;
    padding-top: 40px;
    padding-bottom: 20px;
    color:  #1B1F2A
}
.filter-dropdown:focus-visible{
    outline:1px solid #8b0000;
}