/* @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap"); */

* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: LatoBoldItalic;
  src: url("./fonts/Lato-BoldItalic.ttf");
}

@font-face {
  font-family: LatoBold;
  src: url("./fonts/Lato-Bold.ttf");
}
@font-face {
  font-family: LatoLightItalic;
  src: url("./fonts/Lato-LightItalic.ttf");
}

@font-face {
  font-family: Lato;
  src: url("./fonts/Lato-Light.ttf");
}


@font-face {
  font-family: LatoRegular;
  src: url("./fonts/Lato-Regular.ttf");
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
.content-wraper {
  min-height: 80vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.whiteContainer {
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  background-color: white;
  margin: 0;
  margin-top: 60px;
  padding: 20px;
}
