.message-tab {
  display: flex;
  padding: 40px 0;
  gap: 20px;
}
.message-tab .message-btn-box {
  display: flex;
  align-items: center;
  gap: 5px;
}
.message-tab .btn-text {
  font-family: LatoBold;
  font-size: 25px;
  cursor: pointer;
  color: #dfdfdf;
}
.message-tab .active {
  color: #6a011a;
}
.manage-cards {
  padding: 15px;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  background-color: white;
}

.notification {
  background-color: #dfdfdf;
  border-radius: 50px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
}

.notification.active-noti {
  background-color: #6a011a;
  color: #fff;
}
