.bottomParent {
  padding: 30px;
}
.bottomBox {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  background-color: white;
  padding: 15px 15px;
}
.bottomContent {
  display: flex;
}
.bottomContent h5 {
  font-family: LatoBold;
}
.bottomContent p {
  margin: 0 8px;
  color: gray;
  font-family: Lato;
}
/* .radioBox input[type="radio"] {
  margin: 10px 0;
  padding: 0;
  width: 40px;
  height: 2rem;
} */
.radioBox {
  margin: 0%;
  padding: 0%;
  display: flex;
  align-items: center;
  cursor:pointer;
}
.radioContent {
  margin: 0 10px;
}
.radioContent h5 {
  font-family: LatoBold;
  margin: 0;
  font-size: 16px;
}
.radioContent p {
  color: gray;
  font-family: Lato;
  font-size: 14px;
  margin: 0;
}
.prizeCont {
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

/* HIDE RADIO */
[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  outline: 4px solid transparent;
  border-radius: 100px;
  padding: 3px;
}

/* IMAGE STYLES */

[type="radio"] + img {
  cursor: pointer;
  outline: 4px solid transparent;
  border-radius: 100px;
  padding: 3px;
}

/* CHECKED STYLES */
[type="radio"]:checked + img {
  outline: 4px solid #7b7b7b;
  border-radius: 100px;
  padding: 3px;
}
