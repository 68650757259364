.topParent {
  width: 100%;
}
.topContParent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.product-gallery {
  /* padding: 0 10px; */
}
.product-gallery .product-img-container{
  display: flex;
  flex-direction: row;
}
.product-gallery .grid-container-fb{
  display: flex;
  flex-direction: row;
}
.product-gallery .product-img-container .container-fb{
  display: flex;
  flex-direction: column;
}.product-gallery .product-img-container .container-fb .row{
  display: flex;
  flex-direction: column;
}

.product-img-container {
  display: flex;
  gap: 10px;
}
.main-image {
  border-radius: 10px;
  width: 100%;
}
.product-small-img {
  /* border-radius: 15px; */
  width: 100%;
}
.product-description {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #f3f3f3;
  background-color: #fff;
  margin: 0;
}
.product-description p {
  text-align:justify;
  font-family: Lato;
  font-size: 14px;
  margin: 0;
  padding: 0;
}
.product-detal-content {
  flex: 1;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  background-color: #fff;
  padding: 5%;
}
.product-detal-content .prodcut-name {
  font-size: 15px;
  font-weight: 700;
  font-family: LatoBoldItalic;
  text-decoration: italic;
}
.product-detal-content .product-detail {
  color: gray;
  margin: 0;
  font-size: 15px;
  display: flex;
  gap: 10px;
  padding: 2px 0;
  font-family: Lato;
}

.product-detal-content .product-detail .detial-property {
  color: black;
  font-family: LatoBold;
}
.product-detal-content .product-detail .detial-property.brown {
  color: #8b0000;
}
.product-detal-content .product-price {
  font-family: LatoBold;
  margin: 30px 0;
  font-size: 32px;
}

.product-detal-content .btn-box {
  display: flex;
  justify-content: space-between;
}

.btnMaroon,
.btnGrey {
  width: 150px;
  padding: 10px;
  border-radius: 10px;
  border: none;
  font-family: Lato;
}
.btnMaroon {
  background-color:#6B0019;
  color: white;
  font-weight: 800;
}
.btnGrey {
  background-color: lightgray;
  color: black;
  font-weight: 800;
}

.related-items {
  border: 1px solid #f3f3f3;
  background-color: #fff;
  border-radius: 10px;
  padding: 2%;
}

.related-items .title {
  font-family: LatoBold;
  color: #5f5f5f;
  font-size: 18px;
  text-align: center;
  padding-top: 30px;
}
