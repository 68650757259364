.btn1 {
  color: #6b0019;
  min-width: 200px;
  max-width: 250px;
  width: 100%;
  border-radius: 10px;
  border: 2px solid #6b0019;
  font-family: LatoBold;
  padding: 10px;
}
.btn1:hover {
  background-color: #6b0019;
  color: white;
}
.btn2 {
  background-color: #6b0019;
  min-width: 200px;
  max-width: 250px;
  width: 100%;
  border-radius: 10px;
  color: white;
  padding: 10px;
  border: 1px solid #6b0019;
}
.btn2:hover {
  background-color: rgb(58, 4, 4);
  color: white;
}

.modal-content {
  border-radius: 0;
}

.edit-model {
  padding: 5%;
}
.edit-model .edit-modal-title {
  font-family: Lato;
  font-size: 32px;
  text-align: center;
  color: #6a011a;
}
.edit-model .edit-modal-description {
  font-family: Lato;
  font-size: 17px;
  text-align: center;
  padding: 5% 0;
}

.edit-model .modal-btn-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.faqPage{
  padding-left:10%;
  padding-right:10%;
}
.card-header{
  background: #6a011a;
}
.btn-link{
  color:#fff;
}
.btn-link:hover{
  color:#fff;
}