.product-list-banner {
  margin-bottom: 50px;
}
.product-list-banner .banner-box {
  width: 100%;
  height: 150px;
  background-image: url("/src/Assets/banner.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.product-list-banner .product-list-banner-card {
  max-width: 450px;
  min-width: 300px;
  position: absolute;
  background-color: white;
  border-radius: 4px;
  bottom: -20%;
  left: 5%;
  padding: 30px;
  width: 100%
}
.product-list-banner .product-list-banner-card h3 {
  /* padding: 15px; */
  font-weight: bolder;
  text-align: center;
}

@media only screen and (max-width: 420px) {
  .product-list-banner .banner-box {
    height: 350px;
  }
  .product-list-banner .product-list-banner-card {
    left: 0;
    margin: 0 5px;
  }
  .product-list-banner .product-list-banner-card p {
    font-size: 0.9rem;
  }
  .product-list-banner .product-list-banner-card h3 {
    font-size: 1.4rem;
  }
}
