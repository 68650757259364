.smalCardBox {
  background-color: white;
  margin: 5px;
  padding: 5px;
  box-shadow: 0 0 5px 2px rgb(214, 214, 214);
}
.smalCardBox .heart-icon {
  position: absolute;
  right: 5px;
  top: 10px;
  cursor: pointer;
  font-size: 16px;
}
.smalCardBox .heart {
  color: #d7d7d7;
}
.smalCardBox .heart-liked {
  color: #6b0019;
}
.smalCardBox img {
  width: 100%;
  height: 175px;
  object-fit: cover;
}
.smalCardBox h5 {
  font-size: 12px;
  font-weight: bolder;
  background-color: #fff;
  font-family: LatoBold;
  padding: 0;
  margin: 0;
}
.smalCardBox p {
  font-size: 12px;
  color: gray;
  background-color: #fff;
  font-family: Lato;
  padding: 5px 0;
  margin: 0;
}
.smalCardBox h3 {
  font-style: italic;
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-family: LatoBold;
}
.cardContent {
  /* border: 1px solid ; */
  background-color: #fff;
}
.cardImg {
  position: relative;
}
.cardImg i {
  font-size: 1.5rem;
  position: absolute;
  top: 5%;
  right: 5%;
}
/* 
@media only screen and (max-width: 1190px) {
  .cardContent h5 {
    font-size: 0.9rem;
  }
}
@media only screen and (max-width: 1085px) {
  .cardContent h5 {
    font-size: 0.7rem;
  }
} */
