.checkout-product-detail {
  border: 1px solid #f3f3f3;
  background-color: #fff;
  border-radius: 10px;
  padding: 4%;
}

.checkout-product-detail .title {
  font-family: Lato;
  color: #555555;
  font-size: 18px;
  margin-bottom: 20px;
}

.checkout-product-detail .checkout-product-img {
  width: 100%;
  border-radius: 10px;
}
.checkout-product-detail .name {
  margin: 0;
  padding: 0;
  font-family: LatoBold;
}

.checkout-product-detail .side-box-text {
  margin: 0;
  padding: 0;
  font-family: LatoRegular;
  color: #555555;
}
.checkout-product-detail .address {
  margin: 0;
  padding: 0;
  font-family: Lato;
}

.checkout-product-detail .price {
  font-family: LatoBold;
}

.checkout-product-detail .pay-now {
  background-color: #6b0019;
  min-width: 150px;
  border-radius: 10px;
  color: white;
  border: 2px solid transparent;
  padding: 10px;
  width: 100%;
  font-family: LatoBold;
  cursor:pointer;
}
.checkout-product-detail .pay-disable {
  background-color: #d8d8d8;
  min-width: 150px;
  border-radius: 10px;
  color: white;
  border: 2px solid transparent;
  padding: 10px;
  width: 100%;
  font-family: LatoBold;
  cursor:not-allowed;
}
.checkout-product-detail .pay-now:hover {
  background-color: rgb(58, 4, 4);
  color: white;
}

.checkout-product-detail .text-field-box {
  margin: 20px 0;
}
.checkout-product-detail .text-field-box .input-label {
  font-family: LatoLightItalic;
  display: block;
  margin: 0;
}

.checkout-product-detail .text-field-box .input {
  font-family: LatoBold;
  border: 0;
  border-bottom: 1px solid #f8f8f8;
  outline: none;
  padding: 10px 0;
  width: 100%;
}

.checkout-product-detail button {
  color: #6b0019;
  min-width: 200px;
  border-radius: 10px;
  border: 2px solid #6b0019;
  font-family: LatoBold;
  padding: 10px;
  background-color: transparent;
}
.checkout-product-detail button:hover {
  background-color: #6b0019;
  color: white;
}
