.forMembersSection {
}

.sectionTitle {
  font-family: "Lato", sans-serif;
}

.extratxtBox {
}
.extratxtBox h1 {
  font-weight: normal;
  font-style: oblique;
  color: #525162;
  margin: 0;
  padding: 0;
}

.extratxtBox .suggestion-info-title {
  font-family: LatoLightItalic;
  color: #7b7b7b;
  font-size: 16px;
}
.extratxtBox .color-theme {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
.extratxtBox .color-theme img{
  object-fit: contain;
  cursor: pointer;
}
.extratxtBox .color-theme img.selected{
  filter: drop-shadow(1px 0 0 #6b0019)
  drop-shadow(0 1px 0 #6b0019)
  drop-shadow(-1px 0 0 #6b0019)
  drop-shadow(0 -1px 0 #6b0019);
}

.extratxtBox .color-theme span.selected {
  color: #6b0019;
}

.extratxtBox .color-theme span {
  font-family: LatoBold;
  color: #7B7B7B;
  cursor: pointer;
}

