.parent {
  margin-bottom: 50px;
}
.bannerBox {
  width: 100%;
  height: 400px;
  background-image: url("/src/Assets/banner.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.cardCustom {
  height: 270px;
  max-width: 450px;
  min-width: 300px;
  position: absolute;
  border-radius: 4px;
  background-color: white;
  bottom: -10%;
  left: 5%;
  padding: 30px;
}
.cardCustom h3 {
  /* padding: 15px; */
  font-weight: bolder;
}
.cardCustom p {
  margin: 0;
  /* padding: 0 15px; */
  color: gray;
}
.cardCustom button {
  width: 270px;
  padding: 15px;
  background-color: #6b0019;
  color: white;
  border: none;
  margin: 20px 0;
  outline: none;
  cursor: pointer;
}
.cardCustom button:hover {
  background-color: #8b0000;
  transition: all 0.5s ease;
}
.cardCustom a {
  text-decoration: underline;
  font-style: italic;
  color: #1b1f2a;
  font-family: Lato;
  /* margin: 10px 0 0 15px; */
}
@media only screen and (max-width: 420px) {
  .bannerBox {
    height: 350px;
  }
  .cardCustom {
    left: 0;
    margin: 0 5px;
  }
  .cardCustom p {
    font-size: 0.9rem;
  }
  .cardCustom h3 {
    font-size: 1.4rem;
  }
}
