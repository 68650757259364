.manage-tab {
  display: flex;
  padding: 40px 0;
  gap: 10px
}
.manage-tab h1 {
  font-family: LatoRegular;
  font-size: 22px;
  margin-right: 10px;
  cursor: pointer;
  color:  #DFDFDF;
  min-width: 120px
}
.manage-tab .active {
  color: #000;
}
.manage-cards {
  padding: 15px;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  background-color: white;
}
