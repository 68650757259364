.opinion-card {
    width: 100%;
    background-color: #fff;
    padding: 10px;
  }
  
  .opinion-card .opinion-card-image {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    width: 100%;
    border-radius: 100%;
  }
  
  .opinion-card .opinion-card-name {
    font-family: LatoBold;
    font-size: 16px;
    color: #555555;
    margin: 0;
  }
  .opinion-card .opinion-card-time {
    font-family: LatoBold;
    color: #c3c3c3;
    margin: 0;
    font-size: 16px;
    margin-left: 10px;
  }
  .opinion-card .opinion-card-prize {
    font-family: LatoBold;
  }
  .opinion-card .opinion-card-description {
    margin: 0;
    padding: 0;
    font-family: Lato;
    font-size: 14px;
  }
  