.login button {
    width: 270px;
    padding: 15px;
    background-color: #6b0019;
    color: white;
    border: none;
    margin: 20px auto;
    display: block;
    outline: none;
    cursor: pointer;
}
.login button:hover {
    background-color: #8b0000;
    transition: all 0.5s ease;
}
.login label{
    margin-left:1rem
}
.signUp button {
    width: 270px;
    padding: 15px;
    background-color: #6b0019;
    color: white;
    border: none;
    margin: 20px auto;
    display: block;
    outline: none;
    cursor: pointer;
}
.signUp button:hover {
    background-color: #8b0000;
    transition: all 0.5s ease;
}
.signUp label{
    margin-left:1rem
}