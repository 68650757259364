.vitrine {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.vitrine .purple-btn {
  color: #6b0019;
  min-width: 150px;
  border-radius: 10px;
  border: 2px solid #6b0019;
  font-family: LatoBold;
  padding: 10px !important;
  margin: 5px;
}
.vitrine .purple-btn-full {
  color: #fff;
  background-color: #6b0019 !important;
  min-width: 150px;
  border-radius: 10px;
  border: 2px solid #6b0019;
  font-family: LatoBold;
  padding: 10px !important;
  margin: 5px;
}
.vitrine .purple-btn:hover {
  background-color: #6b0019 !important;
  color: white !important;
}
.vitrine .outline-purple-btn {
  background-color: #6b0019 !important;
  min-width: 150px;
  border-radius: 10px;
  color: white !important;
  border: 2px solid transparent;
  padding: 10px !important;
  margin: 5px;
}
.vitrine .outline-purple-btn:hover {
  background-color: rgb(58, 4, 4) !important;
  color: white !important;
}
i.fa-star{
  color: #ff4500;
  background-color: #fff;
}


.vitrine img {
  width: 100%;
  height:300px;
  object-fit: cover;
  /*padding: 0 10px;*/
}
.vitrine h5 {
  padding: 0;
  font-size: 14px;
  font-weight: bolder;
  margin-top:10px;
  background-color: #fff;
}
.vitrine p {
  font-size: 0.8rem;
  padding: 0px;
  color: gray;
  background-color: #fff;
  margin: 0;
}